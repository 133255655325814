<template>
  <div class="public">
    <div class="container HomePolice">
      <!-- 风险等级，信用等级，预警等级     -->
      <div class="tab-content">
        <el-radio-group v-model="riskarning">
          <el-radio-button :label="1">{{
            this.riskarningList[1]
          }}</el-radio-button>
          <el-radio-button :label="2">{{
            this.riskarningList[2]
          }}</el-radio-button>
          <el-radio-button :label="3">{{
            this.riskarningList[3]
          }}</el-radio-button>
        </el-radio-group>
      </div>
      <!-- 四色码 -->
      <div class="idiot-one">
        <div
          v-if="riskarning === 1"
          class="box box-three"
          v-for="(item, i) in forCodeData"
          :key="i"
        >
          <div class="box-header">
            <span>{{
              item.risk == 1
                ? "低风险"
                : item.risk == 2
                ? "中风险"
                : item.risk == 3
                ? "高风险"
                : ""
            }}</span>
            <span>{{ item.num }}</span
            >家
          </div>
          <div class="box-one">
            <span>较昨日：</span>
            <span>{{ item.yesterdayNum }}</span>
            <span
              ><i
                :class="
                  item.yesterdayNum > 0
                    ? 'el-icon-top t'
                    : item.yesterdayNum < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
              ></i
            ></span>
          </div>
          <div class="box-one">
            <span>较上周：</span>
            <span>{{ item.weekNum }}</span>
            <span
              ><i
                :class="
                  item.weekNum > 0
                    ? 'el-icon-top t'
                    : item.weekNum < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
              ></i
            ></span>
          </div>
          <div class="box-one">
            <span>较上月：</span>
            <span>{{ item.monthNum }}</span>
            <span
              ><i
                :class="
                  item.monthNum > 0
                    ? 'el-icon-top t'
                    : item.monthNum < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
              ></i
            ></span>
          </div>
          <div class="box-one">
            <span>较上年：</span>
            <span>{{ item.yearNum }}</span>
            <span
              ><i
                :class="
                  item.yearNum > 0
                    ? 'el-icon-top t'
                    : item.yearNum < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
              ></i
            ></span>
          </div>
        </div>

        <div
          v-if="riskarning === 2"
          class="box box-four"
          style="justify-content: flex-start; padding-top: 15px"
        >
          <div class="tab">
            <div
              :class="[isxyshow1 == i ? 'newtab-child' : 'tab-child']"
              v-for="(v, i) in cerditStatistic[0]"
              :key="i"
              @click="tabchild1(v, i)"
            >
              <div>{{ v.num }} 家</div>
              <div>{{ v.riskLevel }}</div>
            </div>
          </div>
          <div class="box-one">
            <span>较昨日：</span>
            <span>{{
              this.cerditStatistic[0][this.isxyshow1].yesterdayNum
            }}</span>
            <span
              ><i
                :class="
                  this.cerditStatistic[0][this.isxyshow1].yesterdayNum > 0
                    ? 'el-icon-top t'
                    : this.cerditStatistic[0][this.isxyshow1].yesterdayNum < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
              ></i
            ></span>
          </div>
          <div class="box-one">
            <span>较上周：</span>
            <span>{{ this.cerditStatistic[0][this.isxyshow1].weekNum }}</span>
            <span
              ><i
                :class="
                  this.cerditStatistic[0][this.isxyshow1].weekNum > 0
                    ? 'el-icon-top t'
                    : this.cerditStatistic[0][this.isxyshow1].weekNum < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
              ></i
            ></span>
          </div>
          <div class="box-one">
            <span>较上月：</span>
            <span>{{ this.cerditStatistic[0][this.isxyshow1].monthNum }}</span>
            <span
              ><i
                :class="
                  this.cerditStatistic[0][this.isxyshow1].monthNum > 0
                    ? 'el-icon-top t'
                    : this.cerditStatistic[0][this.isxyshow1].monthNum < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
              ></i
            ></span>
          </div>
          <div class="box-one">
            <span>较上年：</span>
            <span>{{ this.cerditStatistic[0][this.isxyshow1].yearNum }}</span>
            <span
              ><i
                :class="
                  this.cerditStatistic[0][this.isxyshow1].yearNum > 0
                    ? 'el-icon-top t'
                    : this.cerditStatistic[0][this.isxyshow1].yearNum < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
              ></i
            ></span>
          </div>
        </div>
        <div
          v-if="riskarning === 2"
          class="box box-four"
          style="justify-content: flex-start; padding-top: 15px"
        >
          <div class="tab">
            <div
              :class="[isxyshow2 == i ? 'newtab-child' : 'tab-child']"
              v-for="(v, i) in cerditStatistic[1]"
              :key="i"
              @click="tabchild2(v, i)"
            >
              <div>{{ v.num }} 家</div>
              <div>{{ v.riskLevel }}</div>
            </div>
          </div>
          <div class="box-one">
            <span>较昨日：</span>
            <span>{{
              this.cerditStatistic[1][this.isxyshow2].yesterdayNum
            }}</span>
            <span
              ><i
                :class="
                  this.cerditStatistic[1][this.isxyshow2].yesterdayNum > 0
                    ? 'el-icon-top t'
                    : this.cerditStatistic[1][this.isxyshow2].yesterdayNum < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
              ></i
            ></span>
          </div>
          <div class="box-one">
            <span>较上周：</span>
            <span>{{ this.cerditStatistic[1][this.isxyshow2].weekNum }}</span>
            <span
              ><i
                :class="
                  this.cerditStatistic[1][this.isxyshow2].weekNum > 0
                    ? 'el-icon-top t'
                    : this.cerditStatistic[1][this.isxyshow2].weekNum < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
              ></i
            ></span>
          </div>
          <div class="box-one">
            <span>较上月：</span>
            <span>{{ this.cerditStatistic[1][this.isxyshow2].monthNum }}</span>
            <span
              ><i
                :class="
                  this.cerditStatistic[1][this.isxyshow2].monthNum > 0
                    ? 'el-icon-top t'
                    : this.cerditStatistic[1][this.isxyshow2].monthNum < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
              ></i
            ></span>
          </div>
          <div class="box-one">
            <span>较上年：</span>
            <span>{{ this.cerditStatistic[1][this.isxyshow2].yearNum }}</span>
            <span
              ><i
                :class="
                  this.cerditStatistic[1][this.isxyshow2].yearNum > 0
                    ? 'el-icon-top t'
                    : this.cerditStatistic[1][this.isxyshow2].yearNum < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
              ></i
            ></span>
          </div>
        </div>
        <div
          v-if="riskarning === 2"
          class="box box-four"
          style="justify-content: flex-start; padding-top: 15px"
        >
          <div class="tab">
            <div
              :class="[isxyshow3 == i ? 'newtab-child' : 'tab-child']"
              v-for="(v, i) in cerditStatistic[2]"
              :key="i"
              @click="tabchild3(v, i)"
            >
              <div>{{ v.num }} 家</div>
              <div>{{ v.riskLevel }}</div>
            </div>
          </div>
          <div class="box-one">
            <span>较昨日：</span>
            <span>{{
              this.cerditStatistic[2][this.isxyshow3].yesterdayNum
            }}</span>
            <span
              ><i
                :class="
                  this.cerditStatistic[2][this.isxyshow3].yesterdayNum > 0
                    ? 'el-icon-top t'
                    : this.cerditStatistic[2][this.isxyshow3].yesterdayNum < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
              ></i
            ></span>
          </div>
          <div class="box-one">
            <span>较上周：</span>
            <span>{{ this.cerditStatistic[2][this.isxyshow3].weekNum }}</span>
            <span
              ><i
                :class="
                  this.cerditStatistic[2][this.isxyshow3].weekNum > 0
                    ? 'el-icon-top t'
                    : this.cerditStatistic[2][this.isxyshow3].weekNum < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
              ></i
            ></span>
          </div>
          <div class="box-one">
            <span>较上月：</span>
            <span>{{ this.cerditStatistic[2][this.isxyshow3].monthNum }}</span>
            <span
              ><i
                :class="
                  this.cerditStatistic[2][this.isxyshow3].monthNum > 0
                    ? 'el-icon-top t'
                    : this.cerditStatistic[2][this.isxyshow3].monthNum < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
              ></i
            ></span>
          </div>
          <div class="box-one">
            <span>较上年：</span>
            <span>{{ this.cerditStatistic[2][this.isxyshow3].yearNum }}</span>
            <span
              ><i
                :class="
                  this.cerditStatistic[2][this.isxyshow3].yearNum > 0
                    ? 'el-icon-top t'
                    : this.cerditStatistic[2][this.isxyshow3].yearNum < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
              ></i
            ></span>
          </div>
        </div>
        <div
          v-if="riskarning === 2"
          class="box box-four"
          style="justify-content: flex-start; padding-top: 15px"
        >
          <div class="tab">
            <div
              :class="[isxyshow4 == i ? 'newtab-child' : 'tab-child']"
              v-for="(v, i) in cerditStatistic[3]"
              :key="i"
              @click="tabchild4(v, i)"
            >
              <div>{{ v.num }} 家</div>
              <div>{{ v.riskLevel }}</div>
            </div>
          </div>
          <div class="box-one">
            <span>较昨日：</span>
            <span>{{
              this.cerditStatistic[3][this.isxyshow4].yesterdayNum
            }}</span>
            <span
              ><i
                :class="
                  this.cerditStatistic[3][this.isxyshow4].yesterdayNum > 0
                    ? 'el-icon-top t'
                    : this.cerditStatistic[3][this.isxyshow4].yesterdayNum < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
              ></i
            ></span>
          </div>
          <div class="box-one">
            <span>较上周：</span>
            <span>{{ this.cerditStatistic[3][this.isxyshow4].weekNum }}</span>
            <span
              ><i
                :class="
                  this.cerditStatistic[3][this.isxyshow4].weekNum > 0
                    ? 'el-icon-top t'
                    : this.cerditStatistic[3][this.isxyshow4].weekNum < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
              ></i
            ></span>
          </div>
          <div class="box-one">
            <span>较上月：</span>
            <span>{{ this.cerditStatistic[3][this.isxyshow4].monthNum }}</span>
            <span
              ><i
                :class="
                  this.cerditStatistic[3][this.isxyshow4].monthNum > 0
                    ? 'el-icon-top t'
                    : this.cerditStatistic[3][this.isxyshow4].monthNum < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
              ></i
            ></span>
          </div>
          <div class="box-one">
            <span>较上年：</span>
            <span>{{ this.cerditStatistic[3][this.isxyshow4].yearNum }}</span>
            <span
              ><i
                :class="
                  this.cerditStatistic[3][this.isxyshow4].yearNum > 0
                    ? 'el-icon-top t'
                    : this.cerditStatistic[3][this.isxyshow4].yearNum < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
              ></i
            ></span>
          </div>
        </div>

        <div
          v-if="riskarning === 3"
          class="box box-five"
          v-for="(item, ind) in fiveCodeData"
          :key="ind"
        >
          <div class="box-header">
            <span>{{ item.riskLevel }}</span>
            <span>{{ item.num }}</span
            >家
          </div>
          <div class="box-one">
            <span>较昨日：</span>
            <span>{{ item.yesterdayNum }}</span>
            <span
              ><i
                :class="
                  item.yesterdayNum > 0
                    ? 'el-icon-top t'
                    : item.yesterdayNum < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
              ></i
            ></span>
          </div>
          <div class="box-one">
            <span>较上周：</span>
            <span>{{ item.weekNum }}</span>
            <span
              ><i
                :class="
                  item.weekNum > 0
                    ? 'el-icon-top t'
                    : item.weekNum < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
              ></i
            ></span>
          </div>
          <div class="box-one">
            <span>较上月：</span>
            <span>{{ item.monthNum }}</span>
            <span
              ><i
                :class="
                  item.monthNum > 0
                    ? 'el-icon-top t'
                    : item.monthNum < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
              ></i
            ></span>
          </div>
          <div class="box-one">
            <span>较上年：</span>
            <span>{{ item.yearNum }}</span>
            <span
              ><i
                :class="
                  item.yearNum > 0
                    ? 'el-icon-top t'
                    : item.yearNum < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
              ></i
            ></span>
          </div>
          <!--          <div class="box-one">-->
          <!--            <div class="five-box">-->
          <!--              <span class="five-label">{{ item.riskLevel }}</span>-->
          <!--              <div>-->
          <!--                <span>{{ item.num }}</span> 家-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
      <!-- 风险预警区域分布情况 中国地图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>{{ riskarningName }}区域分布图</span>
          </div>
        </div>
        <div class="map-charts">
          <Baidumap
            v-if="regionType >= 3 && areaName"
            v-loading="showbmap"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(9,32,57, 0.1)"
            :regionName="regionName"
            :riskarning="riskarning"
            :flag="flag"
            :cityName="cityName"
            :areaName="areaName"
            :data="baidumapOptions"
          />
          <Pmap
            v-else
            v-loading="showmap"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(9,32,57, 0.1)"
            :regionName="regionName"
            :riskarning="riskarning"
            :cityName="cityName"
            :flag="flag"
            :mapOptions="mapOptions"
          ></Pmap>
        </div>
      </div>
      <!-- 预付码新增变化趋势 折线图图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>{{ riskarningName }}新增变化趋势</span>
          </div>
          <div class="echarts-but">
            <el-radio-group v-model="radio1">
              <el-radio-button
                v-for="item in radioData1"
                :label="item.value"
                :key="item.value"
                >{{ item.name }}</el-radio-button
              >
            </el-radio-group>
          </div>
        </div>
        <div class="charts">
          <LineChart
            v-if="riskarning === 1"
            :chartData="lineChartData1"
            v-loading="lineChart1"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :legendData="lineChartLegend1"
            :units="['家', '家', '家']"
            :type="radio1"
          ></LineChart>
          <LineChart
            v-if="riskarning === 2"
            :chartData="lineChartData1"
            v-loading="lineChart1"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :legendData="lineChartLegend2"
            :units="[
              '家',
              '家',
              '家',
              '家',
              '家',
              '家',
              '家',
              '家',
              '家',
              '家',
            ]"
            :type="radio1"
          ></LineChart>
          <LineChart
            v-if="riskarning === 3"
            :chartData="lineChartData1"
            v-loading="lineChart1"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :legendData="lineChartLegend3"
            :units="['家', '家', '家', '家', '家']"
            :type="radio1"
          ></LineChart>
        </div>
      </div>
      <!-- 预付码累计变化趋势 折线图图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>{{ riskarningName }}累计变化趋势</span>
          </div>
          <div class="echarts-but">
            <el-radio-group v-model="radio2">
              <el-radio-button
                v-for="item in radioData1"
                :label="item.value"
                :key="item.value"
                >{{ item.name }}</el-radio-button
              >
            </el-radio-group>
          </div>
        </div>

        <div class="charts">
          <LineChart
            v-if="riskarning === 1"
            v-loading="lineChart2"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :chartData="lineChartData2"
            :legendData="lineChartLegend1"
            :units="['家', '家', '家']"
            :type="radio2"
          ></LineChart>
          <LineChart
            v-if="riskarning === 2"
            v-loading="lineChart2"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :chartData="lineChartData2"
            :legendData="lineChartLegend2"
            :units="[
              '家',
              '家',
              '家',
              '家',
              '家',
              '家',
              '家',
              '家',
              '家',
              '家',
            ]"
            :type="radio2"
          ></LineChart>
          <LineChart
            v-if="riskarning === 3"
            :chartData="lineChartData2"
            v-loading="lineChart2"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :legendData="lineChartLegend3"
            :units="['家', '家', '家', '家', '家']"
            :type="radio2"
          ></LineChart>
        </div>
      </div>
      <!-- 预付码按城市/地区统计 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>{{ riskarningName }}区域分布数量统计</span>
          </div>
          <div class="h-selct">
            <el-select v-model="hairpinType1" placeholder="请选择" size="mini">
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="item in options"
                :key="item.haripinType"
                :label="item.name"
                :value="item.haripinType"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="element-tab">
          <el-table
            v-if="riskarning === 1"
            :data="tableDataAll"
            v-loading="loading1"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            style="width: 100%"
            :row-key="rowKey"
            lazy
            :load="loadNextNode1"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @sort-change="changeSort"
          >
            <el-table-column
              prop="regionName"
              label="地区"
              sortable
              width="290"
            ></el-table-column>
            <el-table-column prop="num" label="总计" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num }}</span
                ><i
                  :class="
                    scope.row.numSign > 0
                      ? 'num-badge-blue'
                      : scope.row.numSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.numSign > 0
                      ? "+" + scope.row.numSign
                      : scope.row.numSign < 0
                      ? "" + scope.row.numSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="lowNum" label="低风险" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.lowNum }}</span
                ><i
                  :class="
                    scope.row.lowSign > 0
                      ? 'num-badge-blue'
                      : scope.row.lowSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.lowSign > 0
                      ? "+" + scope.row.lowSign
                      : scope.row.lowSign < 0
                      ? "" + scope.row.lowSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="midNum" label="中风险" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.midNum }}</span
                ><i
                  :class="
                    scope.row.midSign > 0
                      ? 'num-badge-blue'
                      : scope.row.midSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.midSign > 0
                      ? "+" + scope.row.midSign
                      : scope.row.midSign < 0
                      ? "" + scope.row.midSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="highNum" label="高风险" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.highNum }}</span
                ><i
                  :class="
                    scope.row.highSign > 0
                      ? 'num-badge-blue'
                      : scope.row.highSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.highSign > 0
                      ? "+" + scope.row.highSign
                      : scope.row.highSign < 0
                      ? "" + scope.row.highSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
          </el-table>
          <el-table
            v-if="riskarning === 2"
            :data="tableDataAll"
            v-loading="loading1"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            style="width: 100%"
            :row-key="rowKey"
            lazy
            :load="loadNextNode1"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @sort-change="changeSort"
          >
            <el-table-column
              prop="regionName"
              label="地区"
              sortable
              width="290"
            >
            </el-table-column>
            <el-table-column prop="num" label="总计" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num }}</span
                ><i
                  :class="
                    scope.row.numSign > 0
                      ? 'num-badge-blue'
                      : scope.row.numSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.numSign > 0
                      ? "+" + scope.row.numSign
                      : scope.row.numSign < 0
                      ? "" + scope.row.numSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="credit3A" label="AAA" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit3A }}</span
                ><i
                  :class="
                    scope.row.credit3ASign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit3ASign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.credit3ASign > 0
                      ? "+" + scope.row.credit3ASign
                      : scope.row.credit3ASign < 0
                      ? "" + scope.row.credit3ASign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="credit2A" label="AA" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit2A }}</span
                ><i
                  :class="
                    scope.row.credit2ASign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit2ASign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.credit2ASign > 0
                      ? "+" + scope.row.credit2ASign
                      : scope.row.credit2ASign < 0
                      ? "" + scope.row.credit2ASign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="credit1A" label="A" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit1A }}</span
                ><i
                  :class="
                    scope.row.credit1ASign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit1ASign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.credit1ASign > 0
                      ? "+" + scope.row.credit1ASign
                      : scope.row.credit1ASign < 0
                      ? "" + scope.row.credit1ASign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="credit3B" label="BBB" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit3B }}</span
                ><i
                  :class="
                    scope.row.credit3BSign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit3BSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.credit3BSign > 0
                      ? "+" + scope.row.credit3BSign
                      : scope.row.credit3BSign < 0
                      ? "" + scope.row.credit3BSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="credit2B" label="BB" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit2B }}</span
                ><i
                  :class="
                    scope.row.credit2BSign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit2BSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.credit2BSign > 0
                      ? "+" + scope.row.credit2BSign
                      : scope.row.credit2BSign < 0
                      ? "" + scope.row.credit2BSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="credit1B" label="B" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit1B }}</span
                ><i
                  :class="
                    scope.row.credit1BSign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit1BSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.credit1BSign > 0
                      ? "+" + scope.row.credit1BSign
                      : scope.row.credit1BSign < 0
                      ? "" + scope.row.credit1BSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="credit3C" label="CCC" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit3C }}</span
                ><i
                  :class="
                    scope.row.credit3CSign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit3CSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.credit3CSign > 0
                      ? "+" + scope.row.credit3CSign
                      : scope.row.credit3CSign < 0
                      ? "" + scope.row.credit3CSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="credit2C" label="CC" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit2C }}</span
                ><i
                  :class="
                    scope.row.credit2CSign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit2CSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.credit2CSign > 0
                      ? "+" + scope.row.credit2CSign
                      : scope.row.credit2CSign < 0
                      ? "" + scope.row.credit2CSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="credit1C" label="C" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit1C }}</span
                ><i
                  :class="
                    scope.row.credit1CSign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit1CSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.credit1CSign > 0
                      ? "+" + scope.row.credit1CSign
                      : scope.row.credit1CSign < 0
                      ? "" + scope.row.credit1CSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="creditD" label="D" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.creditD }}</span
                ><i
                  :class="
                    scope.row.creditDSign > 0
                      ? 'num-badge-blue'
                      : scope.row.creditDSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.creditDSign > 0
                      ? "+" + scope.row.creditDSign
                      : scope.row.creditDSign < 0
                      ? "" + scope.row.creditDSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
          </el-table>
          <el-table
            v-if="riskarning === 3"
            :data="tableDataAll"
            v-loading="loading1"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            style="width: 100%"
            :row-key="rowKey"
            lazy
            :load="loadNextNode1"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @sort-change="changeSort"
          >
            <el-table-column
              prop="regionName"
              label="地区"
              sortable
              width="290"
            >
            </el-table-column>
            <el-table-column prop="num" label="总计" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num }}</span
                ><i
                  :class="
                    scope.row.numSign > 0
                      ? 'num-badge-blue'
                      : scope.row.numSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.numSign > 0
                      ? "+" + scope.row.numSign
                      : scope.row.numSign < 0
                      ? "" + scope.row.numSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="warning1" label="一级预警" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.warning1 }}</span
                ><i
                  :class="
                    scope.row.warning1Sign > 0
                      ? 'num-badge-blue'
                      : scope.row.warning1Sign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.warning1Sign > 0
                      ? "+" + scope.row.warning1Sign
                      : scope.row.numSign < 0
                      ? "" + scope.row.warning1Sign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="warning2" label="二级预警" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.warning2 }}</span
                ><i
                  :class="
                    scope.row.warning2Sign > 0
                      ? 'num-badge-blue'
                      : scope.row.warning2Sign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.warning2Sign > 0
                      ? "+" + scope.row.warning2Sign
                      : scope.row.warning2Sign < 0
                      ? "" + scope.row.warning2Sign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="warning3" label="三级预警" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.warning3 }}</span
                ><i
                  :class="
                    scope.row.warning3Sign > 0
                      ? 'num-badge-blue'
                      : scope.row.warning3Sign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.warning3Sign > 0
                      ? "+" + scope.row.warning3Sign
                      : scope.row.warning3Sign < 0
                      ? "" + scope.row.warning3Sign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="warning4" label="四级预警" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.warning4 }}</span
                ><i
                  :class="
                    scope.row.warning4Sign > 0
                      ? 'num-badge-blue'
                      : scope.row.warning4Sign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.warning4Sign > 0
                      ? "+" + scope.row.warning4Sign
                      : scope.row.warning4Sign < 0
                      ? "" + scope.row.warning4Sign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="warning5" label="五级预警" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.warning5 }}</span
                ><i
                  :class="
                    scope.row.warning5Sign > 0
                      ? 'num-badge-blue'
                      : scope.row.warning5Sign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.warning5Sign > 0
                      ? "+" + scope.row.warning5Sign
                      : scope.row.warning5Sign < 0
                      ? "" + scope.row.warning5Sign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="page-btn" v-if="tableData1.length > 10">
            <el-button type="text" @click="showAll = !showAll"
              >{{ showAll ? "收起全部" : "展开全部"
              }}<i
                class="el-icon-d-arrow-left el-icon--right"
                :class="showAll ? 'rotate-top' : 'rotate-bottom'"
              ></i
            ></el-button>
          </div>
        </div>
      </div>
      <!-- 风险预警按领域/行业统计 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>{{ riskarningName }}领域/行业分布数量统计</span>
          </div>
          <div class="h-selct">
            <el-select v-model="hairpinType2" placeholder="请选择" size="mini">
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="item in options"
                :key="item.haripinType"
                :label="item.name"
                :value="item.haripinType"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="element-tab">
          <el-table
            v-show="riskarning === 1"
            :data="tableDataAll2"
            v-loading="loading2"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            style="width: 100%"
            row-key="industryId"
            lazy
            :load="loadNextNode2"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @sort-change="changeSort2"
          >
            <el-table-column prop="industryName" label="领域/行业" sortable>
            </el-table-column>
            <el-table-column prop="num" label="总计" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num }}</span
                ><i
                  :class="
                    scope.row.numSign > 0
                      ? 'num-badge-blue'
                      : scope.row.numSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.numSign > 0
                      ? "+" + scope.row.numSign
                      : scope.row.numSign < 0
                      ? "" + scope.row.numSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="lowNum" label="低风险" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.lowNum }}</span
                ><i
                  :class="
                    scope.row.lowSign > 0
                      ? 'num-badge-blue'
                      : scope.row.lowSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.lowSign > 0
                      ? "+" + scope.row.lowSign
                      : scope.row.lowSign < 0
                      ? "" + scope.row.lowSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="midNum" label="中风险" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.midNum }}</span
                ><i
                  :class="
                    scope.row.midSign > 0
                      ? 'num-badge-blue'
                      : scope.row.midSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.midSign > 0
                      ? "+" + scope.row.midSign
                      : scope.row.midSign < 0
                      ? "" + scope.row.midSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="highNum" label="高风险" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.highNum }}</span
                ><i
                  :class="
                    scope.row.highSign > 0
                      ? 'num-badge-blue'
                      : scope.row.highSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.highSign > 0
                      ? "+" + scope.row.highSign
                      : scope.row.highSign < 0
                      ? "" + scope.row.highSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
          </el-table>
          <el-table
            v-show="riskarning === 2"
            :data="tableDataAll2"
            v-loading="loading2"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            style="width: 100%"
            row-key="industryId"
            lazy
            :load="loadNextNode2"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @sort-change="changeSort2"
          >
            <el-table-column
              prop="industryName"
              label="领域/行业"
              sortable
              width="290"
            >
            </el-table-column>
            <el-table-column prop="num" label="总计" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num }}</span
                ><i
                  :class="
                    scope.row.numSign > 0
                      ? 'num-badge-blue'
                      : scope.row.numSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.numSign > 0
                      ? "+" + scope.row.numSign
                      : scope.row.numSign < 0
                      ? "" + scope.row.numSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="credit3A" label="AAA" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit3A }}</span
                ><i
                  :class="
                    scope.row.credit3ASign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit3ASign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.credit3ASign > 0
                      ? "+" + scope.row.credit3ASign
                      : scope.row.credit3ASign < 0
                      ? "" + scope.row.credit3ASign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="credit2A" label="AA" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit2A }}</span
                ><i
                  :class="
                    scope.row.credit2ASign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit2ASign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.credit2ASign > 0
                      ? "+" + scope.row.credit2ASign
                      : scope.row.credit2ASign < 0
                      ? "" + scope.row.credit2ASign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="credit1A" label="A" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit1A }}</span
                ><i
                  :class="
                    scope.row.credit1ASign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit1ASign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.credit1ASign > 0
                      ? "+" + scope.row.credit1ASign
                      : scope.row.credit1ASign < 0
                      ? "" + scope.row.credit1ASign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="credit3B" label="BBB" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit3B }}</span
                ><i
                  :class="
                    scope.row.credit3BSign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit3BSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.credit3BSign > 0
                      ? "+" + scope.row.credit3BSign
                      : scope.row.credit3BSign < 0
                      ? "" + scope.row.credit3BSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="credit2B" label="BB" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit2B }}</span
                ><i
                  :class="
                    scope.row.credit2BSign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit2BSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.credit2BSign > 0
                      ? "+" + scope.row.credit2BSign
                      : scope.row.credit2BSign < 0
                      ? "" + scope.row.credit2BSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="credit1B" label="B" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit1B }}</span
                ><i
                  :class="
                    scope.row.credit1BSign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit1BSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.credit1BSign > 0
                      ? "+" + scope.row.credit1BSign
                      : scope.row.credit1BSign < 0
                      ? "" + scope.row.credit1BSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="credit3C" label="CCC" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit3C }}</span
                ><i
                  :class="
                    scope.row.credit3CSign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit3CSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.credit3CSign > 0
                      ? "+" + scope.row.credit3CSign
                      : scope.row.credit3CSign < 0
                      ? "" + scope.row.credit3CSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="credit2C" label="CC" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit2C }}</span
                ><i
                  :class="
                    scope.row.credit2CSign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit2CSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.credit2CSign > 0
                      ? "+" + scope.row.credit2CSign
                      : scope.row.credit2CSign < 0
                      ? "" + scope.row.credit2CSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="credit1C" label="C" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit1C }}</span
                ><i
                  :class="
                    scope.row.credit1CSign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit1CSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.credit1CSign > 0
                      ? "+" + scope.row.credit1CSign
                      : scope.row.credit1CSign < 0
                      ? "" + scope.row.credit1CSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="creditD" label="D" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.creditD }}</span
                ><i
                  :class="
                    scope.row.creditDSign > 0
                      ? 'num-badge-blue'
                      : scope.row.creditDSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.creditDSign > 0
                      ? "+" + scope.row.creditDSign
                      : scope.row.creditDSign < 0
                      ? "" + scope.row.creditDSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
          </el-table>
          <el-table
            v-show="riskarning === 3"
            :data="tableDataAll2"
            v-loading="loading2"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            style="width: 100%"
            row-key="industryId"
            lazy
            :load="loadNextNode2"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @sort-change="changeSort2"
          >
            <el-table-column
              prop="industryName"
              label="领域/行业"
              sortable
              width="290"
            >
            </el-table-column>
            <el-table-column prop="num" label="总计" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num }}</span
                ><i
                  :class="
                    scope.row.numSign > 0
                      ? 'num-badge-blue'
                      : scope.row.numSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.numSign > 0
                      ? "+" + scope.row.numSign
                      : scope.row.numSign < 0
                      ? "" + scope.row.numSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="warning1" label="一级预警" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.warning1 }}</span
                ><i
                  :class="
                    scope.row.warning1Sign > 0
                      ? 'num-badge-blue'
                      : scope.row.warning1Sign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.warning1Sign > 0
                      ? "+" + scope.row.warning1Sign
                      : scope.row.numSign < 0
                      ? "" + scope.row.warning1Sign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="warning2" label="二级预警" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.warning2 }}</span
                ><i
                  :class="
                    scope.row.warning2Sign > 0
                      ? 'num-badge-blue'
                      : scope.row.warning2Sign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.warning2Sign > 0
                      ? "+" + scope.row.warning2Sign
                      : scope.row.warning2Sign < 0
                      ? "" + scope.row.warning2Sign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="warning3" label="三级预警" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.warning3 }}</span
                ><i
                  :class="
                    scope.row.warning3Sign > 0
                      ? 'num-badge-blue'
                      : scope.row.warning3Sign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.warning3Sign > 0
                      ? "+" + scope.row.warning3Sign
                      : scope.row.warning3Sign < 0
                      ? "" + scope.row.warning3Sign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="warning4" label="四级预警" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.warning4 }}</span
                ><i
                  :class="
                    scope.row.warning4Sign > 0
                      ? 'num-badge-blue'
                      : scope.row.warning4Sign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.warning4Sign > 0
                      ? "+" + scope.row.warning4Sign
                      : scope.row.warning4Sign < 0
                      ? "" + scope.row.warning4Sign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="warning5" label="五级预警" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.warning5 }}</span
                ><i
                  :class="
                    scope.row.warning5Sign > 0
                      ? 'num-badge-blue'
                      : scope.row.warning5Sign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.warning5Sign > 0
                      ? "+" + scope.row.warning5Sign
                      : scope.row.warning5Sign < 0
                      ? "" + scope.row.warning5Sign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="page-btn" v-if="tableData2.length > 10">
            <el-button type="text" @click="showAll2 = !showAll2"
              >{{ showAll2 ? "收起全部" : "展开全部"
              }}<i
                class="el-icon-d-arrow-left el-icon--right"
                :class="showAll2 ? 'rotate-top' : 'rotate-bottom'"
              ></i
            ></el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import LineChart from "@/components/charts/line"; // 四码折线图
import Pmap from "@/components/charts/map"; //地图
import {
  regionalRiskInfo,
  riskStatistics,
  riskAdd,
  riskCumulative,
  riskCityStatistics,
  riskIndustryStatistics,
  cerditStatistics,
  regionalCreditInfo,
  earlyWarningStatistics,
  creditCityStatistics,
  warningCityStatistics,
  creditIndustryStatistics,
  warningIndustryStatistics,
  cerditAdd,
  warningAdd,
  creditCumulative,
  warningCumulative,
  regionalEarlyWarningInfo,
} from "@/api/homepolice";
import { cardType } from "@/api/common";
import Baidumap from "@/components/baidumap/index.vue";
export default {
  name: "HomePolice",
  props: {
    regionName: [String],
    areaName: [String],
    cityName: [String],
    flag: [String, Number],
    regionId: [String, Number],
    regionType: [String, Number],
  },
  data() {
    let arr = ["", "风险状况", "信用状况", "风险预警"];
    return {
      showmap: true,
      showbmap: true,
      lineChart1: false,
      lineChart2: false,
      riskarning: 1, //风险预警
      riskarningList: arr,
      lineChartLegend1: ["低风险", "中风险", "高风险"],
      lineChartLegend2: [
        "AAA",
        "AA",
        "A",
        "BBB",
        "BB",
        "B",
        "CCC",
        "CC",
        "C",
        "D",
      ],
      lineChartLegend3: [
        "一级预警",
        "二级预警",
        "三级预警",
        "四级预警",
        "五级预警",
      ],
      lineChartColor1: ["#b64020", "#b5ac2c", "#b4b9c1"],
      lineChartColor2: [
        "#487fef",
        "#73f6bc",
        "#6d56ee",
        "#d85dc5",
        "#f2f9b2",
        "#d43284",
        "#de3227",
        "#d27455",
        "#e1749e",
        "#72f7fd",
      ],
      lineChartColor3: ["#de3227", "#d43385", "#d95ec6", "#d27555", "#f2fab2"],
      riskarningName: arr[1],
      hairpinType1: "",
      hairpinType2: "",
      options: [],
      radio1: "1",
      radio2: "1",
      radioData1: [
        {
          name: "近一周",
          value: "1",
        },
        {
          name: "近一月",
          value: "2",
        },
        {
          name: "近半年",
          value: "3",
        },
        {
          name: "全部",
          value: "4",
        },
      ],
      lineChartData1: {
        xAxisData: [],
        seriesData: [
          {
            name: "高风险",
            data: [],
          },
          {
            name: "中风险",
            data: [],
          },
          {
            name: "低风险",
            data: [],
          },
        ],
      },
      lineChartData2: {
        xAxisData: [],
        seriesData: [
          {
            name: "高风险",
            data: [],
          },
          {
            name: "中风险",
            data: [],
          },
          {
            name: "低风险",
            data: [],
          },
        ],
      },
      showAll: false,
      loading1: false,
      loading2: false,
      tableDataAll: [],
      tableData1: [],
      showAll2: false,
      tableDataAll2: [],
      tableData2: [],
      mapOptions: [],
      baidumapOptions: [],
      forCodeData: [],
      cerditStatistic: [],
      fiveCodeData: [],
      isxyshow1: 0,
      isxyshow2: 0,
      isxyshow3: 0,
      isxyshow4: 0,
    };
  },
  computed: {
    costRequest() {
      return {
        regionName: this.regionName,
        cityName: this.cityName,
        areaName: this.areaName,
        flag: this.flag,
        regionId: this.regionId,
        regionType: this.regionType,
      };
    },
  },
  watch: {
    costRequest: {
      handler(val, oldVal) {
        this.intData();
      },
      deep: true, //增加deep 观察对象的子对象变化
    },
    radio1: function (val) {
      this.riskAdd();
    },
    radio2: function (val) {
      this.riskCumulative();
    },
    riskarning: function (val) {
      this.riskarningName = this.riskarningList[val];
      console.log(this.lineChartData1);
      this.intData();
    },
    hairpinType1: function (val) {
      this.riskCityStatistics();
    },
    hairpinType2: function (val) {
      this.riskIndustryStatistics();
    },
    showAll: function (val) {
      this.showAllData();
    },
    showAll2: function (val) {
      this.showAllData2();
    },
  },
  mounted() {
    if (sessionStorage.getItem("initParams") === "init") {
      this.intData();
    }
  },
  beforeDestroy() {
    window.sessionStorage.setItem("initParams", "init");
  },
  methods: {
    tabchild1: function (a, b) {
      this.isxyshow1 = b;
    },
    tabchild2: function (a, b) {
      this.isxyshow2 = b;
    },
    tabchild3: function (a, b) {
      this.isxyshow3 = b;
    },
    tabchild4: function (a, b) {
      this.isxyshow4 = b;
    },
    intData() {
      this.cardType();
      this.riskAdd();
      this.riskCumulative();
      this.riskCityStatistics();
      this.riskIndustryStatistics();
      if (this.riskarning === 1) {
        this.riskStatistics();
        this.getMapOptionData();
      } else if (this.riskarning === 2) {
        this.cerditStatistics();
        this.getMapOptionData2();
      } else if (this.riskarning === 3) {
        this.earlyWarningStatistics();
        this.getMapOptionData3();
      }
    },
    cardType() {
      cardType().then((res) => {
        this.options = res.data;
      });
    },
    getMapOptionData() {
      this.showbmap = true;
      this.showmap = true;
      regionalRiskInfo({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      })
        .then((res) => {
          //console.log('风险预警区域分布情况:',res)
          //this.mapOptions = res.data;
          let data = [];
          res.data.forEach((item) => {
            data.push({
              name: item.regionName,
              value: item.num,
              data: item.data,
            });
          });
          this.mapOptions = data;

          this.showmap = false;
          if (this.regionType >= 3 && this.areaName) {
            this.baidumapOptions = data;
            this.showbmap = false;
          }

          // res.data.forEach((item,i) => {
          //   this.mapOptions.push({
          //     name:item.regionName,
          //     value:item.num,
          //     data:item.data
          //   })
          // });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getMapOptionData2() {
      this.mapOptions = [];
      this.showbmap = true;
      this.showmap = true;
      regionalCreditInfo({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      })
        .then((res) => {
          // console.log("商户区域分布情况:", res);
          //this.mapOptions = res.data;

          let data = [];
          res.data.forEach((item) => {
            data.push({
              name: item.regionName,
              value: item.num,
              data: item.creditData,
            });
          });
          this.mapOptions = data;
          this.showmap = false;
          if (this.regionType >= 3 && this.areaName) {
            this.baidumapOptions = data;
            this.showbmap = false;
          }

          // console.log(this.mapOptions, "ditu");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getMapOptionData3() {
      this.showbmap = true;
      this.showmap = true;
      regionalEarlyWarningInfo({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      })
        .then((res) => {
          //console.log('风险预警区域分布情况:',res)
          //this.mapOptions = res.data;
          let data = [];
          res.data.forEach((item) => {
            data.push({
              name: item.regionName,
              value: item.num,
              data: item.earlyWarningData,
            });
          });
          this.mapOptions = data;
          this.showmap = false;
          if (this.regionType >= 3 && this.areaName) {
            this.baidumapOptions = data;
            this.showbmap = false;
          }

          // res.data.forEach((item,i) => {
          //   this.mapOptions.push({
          //     name:item.regionName,
          //     value:item.num,
          //     data:item.data
          //   })
          // });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    riskStatistics() {
      riskStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      })
        .then((res) => {
          //console.log('风险等级情况统计:',res)
          this.forCodeData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cerditStatistics() {
      cerditStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      })
        .then((res) => {
          function fenge(arr, N) {
            console.log(arr, N);
            var result = [];
            for (var i = 0; i < arr.length; i += N) {
              result.push(arr.slice(i, i + N));
            }
            return result;
          }
          let list = fenge(res.data, 3);
          console.log(list);

          this.cerditStatistic = list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    earlyWarningStatistics() {
      earlyWarningStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      })
        .then((res) => {
          //console.log('风险等级情况统计:',res)
          this.fiveCodeData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    riskAdd() {
      this.lineChartData1 = {
        xAxisData: [],
        seriesData: [],
      };
      this.lineChart1 = true;
      console.log(this.riskarning);
      if (this.riskarning === 1) {
        riskAdd({
          regionId: this.regionId,
          regionType: this.regionType,
          flag: this.flag,
          type: this.radio1,
        })
          .then((res) => {
            //console.log('风险商户新增变化趋势',res)
            this.lineChart1 = false;
            if (res.data != null) {
              this.lineChartData1 = {
                xAxisData: res.data.x_axis,
                seriesData: [
                  {
                    name: "高风险",
                    data: res.data.highs,
                    color: this.lineChartColor1[0],
                  },
                  {
                    name: "中风险",
                    data: res.data.mids,
                    color: this.lineChartColor1[1],
                  },
                  {
                    name: "低风险",
                    data: res.data.lows,
                    color: this.lineChartColor1[2],
                  },
                ],
              };
            } else {
              this.lineChartData1 = {
                xAxisData: [],
                seriesData: this.lineChartLegend1.map((v) => {
                  return {
                    name: v,
                    data: [],
                  };
                }),
              };
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (this.riskarning === 2) {
        cerditAdd({
          regionId: this.regionId,
          regionType: this.regionType,
          flag: this.flag,
          type: this.radio1,
        })
          .then((res) => {
            this.lineChart1 = false;
            //console.log('风险商户新增变化趋势',res)
            if (res.data != null) {
              this.lineChartData1 = {
                xAxisData: res.data.x_axis,
                seriesData: [
                  {
                    name: "AAA",
                    data: res.data.numCredit3As,
                    color: this.lineChartColor2[0],
                  },
                  {
                    name: "AA",
                    data: res.data.numCredit2As,
                    color: this.lineChartColor2[1],
                  },
                  {
                    name: "A",
                    data: res.data.numCredit1As,
                    color: this.lineChartColor2[2],
                  },
                  {
                    name: "BBB",
                    data: res.data.numCredit3Bs,
                    color: this.lineChartColor2[3],
                  },
                  {
                    name: "BB",
                    data: res.data.numCredit2Bs,
                    color: this.lineChartColor2[4],
                  },
                  {
                    name: "B",
                    data: res.data.numCredit1Bs,
                    color: this.lineChartColor2[5],
                  },
                  {
                    name: "CCC",
                    data: res.data.numCredit3Cs,
                    color: this.lineChartColor2[6],
                  },
                  {
                    name: "CC",
                    data: res.data.numCredit2Cs,
                    color: this.lineChartColor2[7],
                  },
                  {
                    name: "C",
                    data: res.data.numCredit1Cs,
                    color: this.lineChartColor2[8],
                  },
                  {
                    name: "D",
                    data: res.data.numCreditDs,
                    color: this.lineChartColor2[9],
                  },
                ],
              };
            } else {
              this.lineChartData1 = {
                xAxisData: [],
                seriesData: this.lineChartLegend2.map((v) => {
                  return {
                    name: v,
                    data: [],
                  };
                }),
              };
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (this.riskarning === 3) {
        warningAdd({
          regionId: this.regionId,
          regionType: this.regionType,
          flag: this.flag,
          type: this.radio1,
        })
          .then((res) => {
            this.lineChart1 = false;
            //console.log('风险商户新增变化趋势',res)
            if (res.data != null) {
              this.lineChartData1 = {
                xAxisData: res.data.x_axis,
                seriesData: [
                  {
                    name: "一级预警",
                    data: res.data.numWarning1s,
                    color: this.lineChartColor3[0],
                  },
                  {
                    name: "二级预警",
                    data: res.data.numWarning2s,
                    color: this.lineChartColor3[1],
                  },
                  {
                    name: "三级预警",
                    data: res.data.numWarning3s,
                    color: this.lineChartColor3[2],
                  },
                  {
                    name: "四级预警",
                    data: res.data.numWarning4s,
                    color: this.lineChartColor3[3],
                  },
                  {
                    name: "五级预警",
                    data: res.data.numWarning5s,
                    color: this.lineChartColor3[4],
                  },
                ],
              };
            } else {
              this.lineChartData1 = {
                xAxisData: [],
                seriesData: this.lineChartLegend3.map((v) => {
                  return {
                    name: v,
                    data: [],
                  };
                }),
              };
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    riskCumulative() {
      this.lineChartData2 = {
        xAxisData: [],
        seriesData: [],
      };
      this.lineChart2 = true;
      if (this.riskarning === 1) {
        riskCumulative({
          regionId: this.regionId,
          regionType: this.regionType,
          flag: this.flag,
          type: this.radio2,
        })
          .then((res) => {
            //console.log('风险商户累计变化趋势',res)
            this.lineChart2 = false;
            if (res.data != null) {
              this.lineChartData2 = {
                xAxisData: res.data.x_axis,
                seriesData: [
                  {
                    name: "高风险",
                    data: res.data.highs,
                    color: this.lineChartColor1[0],
                  },
                  {
                    name: "中风险",
                    data: res.data.mids,
                    color: this.lineChartColor1[1],
                  },
                  {
                    name: "低风险",
                    data: res.data.lows,
                    color: this.lineChartColor1[2],
                  },
                ],
              };
            } else {
              this.lineChartData2 = {
                xAxisData: [],
                seriesData: this.lineChartLegend1.map((v) => {
                  return {
                    name: v,
                    data: [],
                  };
                }),
              };
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (this.riskarning === 2) {
        creditCumulative({
          regionId: this.regionId,
          regionType: this.regionType,
          flag: this.flag,
          type:this.radio2
        }).then(res=>{
          this.lineChart2 = false;
          //console.log('风险商户新增变化趋势',res)
          if(res.data!=null){
            this.lineChartData2={
              xAxisData: res.data.x_axis,
              seriesData: [
                {
                  name: 'AAA',
                  data:res.data.numCredit3As,
                  color: this.lineChartColor2[0],
                },{
                  name: 'AA',
                  data:res.data.numCredit2As,
                  color: this.lineChartColor2[1],
                },{
                  name: 'A',
                  data:res.data.numCredit1As,
                  color: this.lineChartColor2[2],
                },{
                  name: 'BBB',
                  data:res.data.numCredit3Bs,
                  color: this.lineChartColor2[3],
                },{
                  name: 'BB',
                  data:res.data.numCredit2Bs,
                  color: this.lineChartColor2[4],
                },{
                  name: 'B',
                  data:res.data.numCredit1Bs,
                  color: this.lineChartColor2[5],
                },{
                  name: 'CCC',
                  data:res.data.numCredit3Cs,
                  color: this.lineChartColor2[6],
                },{
                  name: 'CC',
                  data:res.data.numCredit2Cs,
                  color: this.lineChartColor2[7],
                },{
                  name: 'C',
                  data:res.data.numCredit1Cs,
                  color: this.lineChartColor2[8],
                },{
                  name: 'D',
                  data:res.data.numCreditDs,
                  color: this.lineChartColor2[9],
                }
              ]
            }
          }else {
            this.lineChartData2 = {
              xAxisData: [],
              seriesData: this.lineChartLegend2.map(v => {
                return {
                  name: v,
                  data: []
                }
              })
            }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (this.riskarning === 3) {
        warningCumulative({
          regionId: this.regionId,
          regionType: this.regionType,
          flag: this.flag,
          type:this.radio2
        }).then(res=>{
          this.lineChart2 = false;
          //console.log('风险商户新增变化趋势',res)
          if(res.data!=null){
            this.lineChartData2={
              xAxisData: res.data.x_axis,
              seriesData: [
                {
                  name: '一级预警',
                  data:res.data.numWarning1s,
                  color: this.lineChartColor3[0],
                },{
                  name: '二级预警',
                  data:res.data.numWarning2s,
                  color: this.lineChartColor3[1],
                },{
                  name: '三级预警',
                  data:res.data.numWarning3s,
                  color: this.lineChartColor3[2],
                },{
                  name: '四级预警',
                  data:res.data.numWarning4s,
                  color: this.lineChartColor3[3],
                },{
                  name: '五级预警',
                  data:res.data.numWarning5s,
                  color: this.lineChartColor3[4],
                }
              ]
            }
          }else{
            this.lineChartData2={
              xAxisData: [],
              seriesData: this.lineChartLegend3.map(v =>{
                return {
                  name:v,
                  data:[]
                }
              })
            }
          }
        }).catch(err =>{
          console.log(err)
        })
          .then((res) => {
            //console.log('风险商户新增变化趋势',res)
            if (res.data != null) {
              this.lineChartData2 = {
                xAxisData: res.data.x_axis,
                seriesData: [
                  {
                    name: "一级预警",
                    data: res.data.numWarning1s,
                    color: this.lineChartColor3[0],
                  },
                  {
                    name: "二级预警",
                    data: res.data.numWarning2s,
                    color: this.lineChartColor3[1],
                  },
                  {
                    name: "三级预警",
                    data: res.data.numWarning3s,
                    color: this.lineChartColor3[2],
                  },
                  {
                    name: "四级预警",
                    data: res.data.numWarning4s,
                    color: this.lineChartColor3[3],
                  },
                  {
                    name: "五级预警",
                    data: res.data.numWarning5s,
                    color: this.lineChartColor3[4],
                  },
                ],
              };
            } else {
              this.lineChartData2 = {
                xAxisData: [],
                seriesData: this.lineChartLegend3.map((v) => {
                  return {
                    name: v,
                    data: [],
                  };
                }),
              };
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    riskCityStatistics() {
      this.tableDataAll = [];
      this.loading1 = true;
      if (this.riskarning === 1) {
        riskCityStatistics({
          hairpinType: this.hairpinType1,
          regionId: this.regionId,
          regionType: this.regionType,
          flag: this.flag,
        }).then((res) => {
          this.loading1 = false;
          this.tableDataAll = [];
          this.$nextTick(() => {
            this.tableData1 = res.data || [];
            this.tableDataAll = this.tableData1.slice(0, 10);
            this.showAllData();
          });
        });
      } else if (this.riskarning === 2) {
        creditCityStatistics({
          hairpinType: this.hairpinType1,
          regionId: this.regionId,
          regionType: this.regionType,
          flag: this.flag,
        }).then((res) => {
          this.loading1 = false;
          this.tableDataAll = [];
          this.$nextTick(() => {
            this.tableData1 = res.data || [];
            this.tableDataAll = this.tableData1.slice(0, 10);
            this.showAllData();
          });
        });
      } else if (this.riskarning === 3) {
        warningCityStatistics({
          hairpinType: this.hairpinType1,
          regionId: this.regionId,
          regionType: this.regionType,
          flag: this.flag,
        }).then((res) => {
          this.loading1 = false;
          this.tableDataAll = [];
          this.$nextTick(() => {
            this.tableData1 = res.data || [];
            this.tableDataAll = this.tableData1.slice(0, 10);
            this.showAllData();
          });
        });
      }
    },
    showAllData() {
      if (!this.showAll) {
        this.tableDataAll = this.tableData1.slice(0, 10);
      } else {
        this.tableDataAll = this.tableData1;
      }
    },
    //确保row-key不重复
    rowKey(row) {
      return row.regionName + row.regionId;
    },
    loadNextNode1(tree, treeNode, resolve) {
      //console.log(tree, treeNode, resolve)
      if (this.riskarning === 1) {
        riskCityStatistics({
          hairpinType: this.hairpinType1,
          regionId: tree.regionId,
          regionType: tree.regionType,
          flag: tree.regionType,
        }).then((res) => {
          resolve(res.data || []);
        });
      } else if (this.riskarning === 2) {
        creditCityStatistics({
          hairpinType: this.hairpinType1,
          regionId: tree.regionId,
          regionType: tree.regionType,
          flag: tree.regionType,
        }).then((res) => {
          resolve(res.data || []);
        });
      } else if (this.riskarning === 3) {
        warningCityStatistics({
          hairpinType: this.hairpinType1,
          regionId: tree.regionId,
          regionType: tree.regionType,
          flag: tree.regionType,
        }).then((res) => {
          resolve(res.data || []);
        });
      }
    },
    riskIndustryStatistics() {
      this.loading2 = true;
      this.tableDataAll2 = [];
      if (this.riskarning === 1) {
        riskIndustryStatistics({
          hairpinType: this.hairpinType2,
          regionId: this.regionId,
          regionType: this.regionType,
          flag: this.flag,
        }).then((res) => {
          //console.log('风险商户按领域/行业统计',res)
          res.data.forEach((item) => {
            item.hasChildren = true;
          });
          this.loading2 = false;
          this.tableDataAll2 = [];
          this.$nextTick(() => {
            this.tableData2 = res.data || [];
            this.tableDataAll2 = this.tableData2.slice(0, 10);
            this.showAllData2();
          });
        });
      } else if (this.riskarning === 2) {
        creditIndustryStatistics({
          hairpinType: this.hairpinType2,
          regionId: this.regionId,
          regionType: this.regionType,
          flag: this.flag,
        }).then((res) => {
          //console.log('风险商户按领域/行业统计',res)
          res.data.forEach((item) => {
            item.hasChildren = true;
          });
          this.loading2 = false;
          this.tableDataAll2 = [];
          this.$nextTick(() => {
            this.tableData2 = res.data || [];
            this.tableDataAll2 = this.tableData2.slice(0, 10);
            this.showAllData2();
          });
        });
      } else if (this.riskarning === 3) {
        warningIndustryStatistics({
          hairpinType: this.hairpinType2,
          regionId: this.regionId,
          regionType: this.regionType,
          flag: this.flag,
        }).then((res) => {
          //console.log('风险商户按领域/行业统计',res)
          res.data.forEach((item) => {
            item.hasChildren = true;
          });
          this.loading2 = false;
          this.tableDataAll2 = [];
          this.$nextTick(() => {
            this.tableData2 = res.data || [];
            this.tableDataAll2 = this.tableData2.slice(0, 10);
            this.showAllData2();
          });
        });
      }
    },
    showAllData2() {
      if (!this.showAll2) {
        this.tableDataAll2 = this.tableData2.slice(0, 10);
      } else {
        this.tableDataAll2 = this.tableData2;
      }
    },
    loadNextNode2(tree, treeNode, resolve) {
      //console.log(tree, treeNode, resolve)
      if (this.riskarning === 1) {
        riskIndustryStatistics({
          hairpinType: this.hairpinType2,
          industryId: tree.industryId,
          regionId: this.regionId,
          regionType: this.regionType,
          flag: this.flag,
        }).then((res) => {
          resolve(res.data);
        });
      } else if (this.riskarning === 2) {
        creditIndustryStatistics({
          hairpinType: this.hairpinType2,
          industryId: tree.industryId,
          regionId: this.regionId,
          regionType: this.regionType,
          flag: this.flag,
        }).then((res) => {
          resolve(res.data);
        });
      } else if (this.riskarning === 3) {
        warningIndustryStatistics({
          hairpinType: this.hairpinType2,
          industryId: tree.industryId,
          regionId: this.regionId,
          regionType: this.regionType,
          flag: this.flag,
        }).then((res) => {
          resolve(res.data);
        });
      }
    },
    changeSort(column, prop, order) {
      this.tableDataAll = this.tableData1;
      this.showAll = true;
    },
    changeSort2(column, prop, order) {
      this.tableDataAll2 = this.tableData2;
      this.showAll2 = true;
    },
  },
  components: {
    LineChart,
    Pmap,
    Baidumap,
  },
};
</script>

<style lang="scss" scoped >
.HomePolice {
  .tab-content {
    margin: 0 30px 20px 30px;
  }
  // 四色码
  .idiot-one {
    display: flex;
    flex-wrap: wrap;
    height: 240px;
    margin: 0 30px 30px 30px;
    border: 1px solid #17688d;
    background: linear-gradient(
      to bottom,
      rgba(43, 202, 255, 0.1),
      #0000,
      rgba(43, 202, 255, 0.1)
    );
    .box {
      width: 33%;
      text-align: center;
      box-sizing: border-box;
      &.box-four {
        width: 28%;
      }
      &.box-five {
        width: calc(100% / 5);
      }
      &.four {
        width: 24.9%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        & > .box-one {
          width: 100%;
          .four-box {
            width: 100%;
            font-size: 16px;
            span {
              width: auto;
              display: inline-block;
              font-size: 26px;
              color: #208dfd;
            }
            .four-label {
              width: 124px;
              margin-right: 10px;
              display: inline-block;
              text-align: right;
              color: #fff;
              font-size: 18px;
            }
          }
        }
        &.box:nth-child(3) {
          border-right-width: 1px;
        }
      }
      &.five {
        width: 19.9%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        & > .box-one {
          width: 100%;
          .five-box {
            width: 100%;
            font-size: 16px;
            span {
              text-align: center;
              width: 100%;
              font-size: 26px;
              color: #208dfd;
            }
            .five-label {
              margin-bottom: 5px;
              display: block;
              color: #fff;
              font-size: 18px;
            }
          }
        }

        &.box:nth-child(3) {
          border-right-width: 1px;
        }
      }
      .box-header {
        color: #b4b9c1;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 14px;
        span:nth-child(1) {
          font-size: 18px;
        }
        span:nth-child(2) {
          font-size: 30px;
          margin: 0 10px;
        }
      }
      .box-one {
        display: flex;
        justify-content: center;
        font-size: 14px;
        line-height: 32px;
        span:nth-child(1) {
          color: #9fa1a7;
        }
        span:nth-child(2) {
          color: #eee;
          width: 60px;
          text-align: left;
        }
        span:nth-child(3) {
          color: #eee;
          width: 10px;
          font-size: 16px;
          .t {
            color: #ffba00;
            font-weight: bold;
          }
          .b {
            color: #59d2f3;
            font-weight: bold;
          }
        }
      }
    }

    & .box-four:nth-child(4) {
      width: 16%;
    }
    .box:nth-child(1),
    .box:nth-child(2),
    .box:nth-child(3),
    .box:nth-child(4),
    .box:nth-child(5) {
      border-right: 1px solid #ddd;
      border-image: linear-gradient(
          #0000,
          #0000,
          #17688d,
          #17688d,
          #0000,
          #0000
        )
        30 30;
    }

    .box-three:nth-child(3),
    .box-five:nth-child(5) {
      border-right: 0px solid #ddd;
    }
  }
  .tab {
    width: 100%;
    height: 52px;
    margin-bottom: 20px;
    // background: red;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .tab-child {
      width: 87px;
      height: 46px;
      border: 1px solid #2783ad;
      border-radius: 5px;
      background: rgba(46, 289, 255, 0.1);
      color: #02eaff;
      font-size: 16px;
      cursor: pointer;
      & > div {
        line-height: 22px;
        text-align: center;
      }
    }
    .newtab-child {
      width: 87px;
      height: 46px;
      border: 1px solid #2783ad;
      border-radius: 5px;
      background: #165880;
      color: white;
      font-size: 16px;
      position: relative;
      cursor: pointer;
      & > div {
        line-height: 22px;
        text-align: center;
      }
    }
    .newtab-child::after {
      content: "";
      position: absolute;
      top: 44px;
      left: 46%;
      margin-left: -4px;
      border-top: 7px solid #165880;
      border-bottom: 7px solid transparent;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      z-index: 1;
    }

    .newtab-child::before {
      content: "";
      position: absolute;
      top: 45px;
      left: 46%;
      margin-left: -5px;
      border-top: 8px solid #2783ad;
      border-bottom: 8px solid transparent;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      z-index: 1;
    }
  }
}
</style>
